import React from 'react';
import MenuItem from '../menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';
import { getLoginUrl } from '../../../../../../app/shared/util/url-utils';
import { NavDropdown } from './menu-components';
import { ChangePlan, MyAccount, SignOut } from '../../icon-svg/icon';
import { useAppSelector } from '../../../../../../app/config/store';

const accountMenuItemsAuthenticated = ({ subscriptionPlanType, getMyAccountURL, getPricingURL }) => (
  <>
    <DropdownItem id="my-account" tag="a" href={getMyAccountURL()} target="_blank" data-cy="login">
      <MyAccount iconId={'my-account'} />
      My Account
    </DropdownItem>
    {subscriptionPlanType?.toLowerCase() === 'normal_plan_user' && (
      <DropdownItem id="change-plan" tag="a" href={getPricingURL()} target="_blank" data-cy="login">
        <ChangePlan iconId="change-plan" />
        Change Plan
      </DropdownItem>
    )}
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      <SignOut iconId={'sign-out-alt'} />
      Sign Out
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <DropdownItem id="login-item" tag="a" href={getLoginUrl()} data-cy="login">
      <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </DropdownItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, userName = '' }) => {
  const subscriptionPlanType = useAppSelector(state => state?.authentication?.account?.subscriptionPlanType);
  const isDev = process.env.IS_DEV;
  const getMyAccountURL = () => (isDev ? 'https://cpapilot-dev-php8.swiftweb.site/my-account/' : 'https://www.cpapilot.com/my-account/');
  const getPricingURL = () =>
    isDev ? 'https://cpapilot-dev-php8.swiftweb.site/pricing-plans/' : 'https://www.cpapilot.com/pricing-plans/';

  return (
    <NavDropdown name={userName} id="account-menu" data-cy="accountMenu">
      {isAuthenticated ? accountMenuItemsAuthenticated({ subscriptionPlanType, getMyAccountURL, getPricingURL }) : accountMenuItems()}
    </NavDropdown>
  );
};

export default AccountMenu;
