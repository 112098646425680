import React, { useState, useRef, useEffect, useCallback } from 'react';
import { CheckIcon, CopyIcon, DisLikeIcon, EllipsisIcon, LikeIcon, PrintIcon } from '../../shared/icon-svg/icon';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { updateEntity as updateUserReaction } from '../../entities/generated-message/generated-message.reducer';
import { useAppDispatch } from '../../../../../app/config/store';
import { userChatId as getUserChatId } from '../../entities/generated-message/generated-message.reducer';
import { Dropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import AiResponsePrint from '../../entities/ai-chat/aiResponsePrint';
import useClipboard from 'react-use-clipboard';
import Downloader from './Downloader';

const LikeDislikeButton = ({ message, userNameInital, aiResp, cta, chatId = null }: any) => {
  const dispatch = useAppDispatch();
  const [aiResponse, setAiResponse] = useState<any>();
  const [messageContext, setMessageContext] = useState<any>();
  const [showButtons, setShowButtons] = useState<boolean>(cta);
  const [selectedMessage, setSelectedMessage] = useState<any>(null);
  const [showReasons, setShowReasons] = useState(false);
  const [inputData, setInputData] = useState('');
  const [messId, setMessId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCopiedShow, setIsCopiedShow] = useState(false); // To control tick icon visibility
  const [showTooltip, setShowTooltip] = useState(true); // To control tooltip visibility
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showEllipsis, setShowEllipsis] = useState(true); // State to control visibility of the ellipsis icon
  const LikeDislikeIconId = messageContext?.id ? `LikedIcon-${messageContext.id}` : 'LikedIcon-default';
  const DislikeIconId = messageContext?.id ? `DisLikeIcon-${messageContext.id}` : 'DisLikeIcon-default';
  const EllipsisIconId = messageContext?.id ? `EllipsisIcon-${messageContext.id}` : 'EllipsisIcon-default';
  const CopyIconId = messageContext?.id ? `CopyIcon-${messageContext.id}` : 'CopyIcon-default';
  const PrintIconId = messageContext?.id ? `PrintIcon-${messageContext.id}` : 'PrintIcon-default';
  const [isCopied, setIsCopied] = useClipboard(aiResponse?.props?.children);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const printMsgRef = useRef(null);
  const dropdownRef = useRef(null); // Ref to detect clicks outside

  const IconWithTooltip = ({ id, tooltipText }) => {
    const [iconTooltipOpen, setIconTooltipOpen] = useState(false);
    const toggle = () => setIconTooltipOpen(!iconTooltipOpen);
    return (
      <Tooltip placement="bottom" isOpen={iconTooltipOpen} target={id} toggle={toggle}>
        {tooltipText}
      </Tooltip>
    );
  };

  useEffect(() => {
    setAiResponse(aiResp);
  }, [aiResp]);

  useEffect(() => {
    if (cta) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    setShowButtons(cta);
  }, [cta]);

  const udpdateReaction = async obj => {
    try {
      dispatch(updateUserReaction(obj));
    } catch (e) {
      console.log(e);
    }
  };

  const getResult = async () => {
    const { payload } = await dispatch(getUserChatId(chatId));
    // setResult(payload.data);
    setMessageContext({ id: payload?.data?.data?.messageId, userReaction: 'Neutral' });
  };

  useEffect(() => {
    if (chatId != null) {
      getResult();
      // setMessageContext({ id: result?.data.messageId, userReaction: 'Neutral' })
    }
  }, [chatId]);

  useEffect(() => {
    setMessageContext(message);
  }, [message]);

  const handleAction = (type: 'Liked' | 'Disliked') => {
    let tempStatus;
    if (messageContext?.userReaction === type) {
      tempStatus = null;
    } else {
      tempStatus = type;
    }
    if (type == 'Disliked') {
      setShowReasons(messageContext?.userReaction !== 'Disliked' ? !showReasons : false);
    } else if (type == 'Liked') {
      setShowReasons(false);
    }
    setMessageContext(prevMessageContext => {
      return { ...prevMessageContext, userReaction: tempStatus == null ? 'Neutral' : tempStatus };
    });
    udpdateReaction({ id: messageContext.id, userReaction: tempStatus == null ? 'Neutral' : tempStatus });
    setMessId(messageContext.id);
    setShowEllipsis(true); // Show the ellipsis icon after print
  };

  // HandlePrintFuntion
  const handlePrintMessage = useReactToPrint({
    contentRef: printMsgRef, // ensures we are printing the correct content
    documentTitle: 'Message',
    preserveAfterPrint: true,

    pageStyle: `
    @page { 
      size: A4; 
      margin: 0;
      padding:10mm;
    }
    
    body { 
      font-size: 14px; 
      margin: 10mm;
    }

  `,

    onBeforePrint: React.useCallback(() => {
      console.log('`onBeforePrint` called');
      return Promise.resolve();
    }, []),

    onAfterPrint: React.useCallback(() => {
      console.log('`onAfterPrint` called');
      setSelectedMessage(null);
    }, []),

    onPrintError: (location, error) => {
      console.error(`Print error at ${location}:`, error);
    },
  });

  // useEffect(() => {
  //   console.log("here hit")
  //   if (selectedMessage && selectedMessage.length > 0) {
  //     setTimeout(() => {
  //       handlePrintMessage();
  //     }, 500);
  //   }
  // }, [selectedMessage]);

  const printMessages = async (msgId: string) => {
    // setSelectedMessage(null);
    setShowEllipsis(true); // Show the ellipsis icon after print

    if (msgId && aiResponse?.props?.children) {
      setSelectedMessage(aiResponse?.props?.children);
      setTimeout(() => handlePrintMessage(), 500);
    } else {
      setSelectedMessage(null);
    }
  };

  const handleFeedback = e => {
    e.preventDefault();
    if (!inputData.trim()) {
      setErrorMessage('Please enter valid reason');
    } else {
      setErrorMessage('');
      udpdateReaction({ id: messageContext.id, userRatingReason: inputData.trim() });
      setShowReasons(false);
    }
  };

  const handleClearShowReason = () => {
    setShowReasons(false);
  };

  const renderUserMessage = () => (
    <div className="usertext">
      <strong>
        <span>{userNameInital}</span> You
      </strong>
      {message?.userPrompt?.promptText ?? message?.aiChat?.title ?? message}
    </div>
  );

  const renderAIResponse = () => (
    <div className="cpaText">
      <strong>
        <img className="cpapilot-chat-logo" src="content/images/logo.png" alt="Logo" />
        CPA Pilot
      </strong>
    </div>
  );

  // New useEffect to scroll when 'showReasons' state changes
  useEffect(() => {
    if (showReasons && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [showReasons]); // Trigger scroll when showReasons state changes

  const handleCopy = useCallback(() => {
    setIsCopied();
    setIsCopiedShow(true);
    setShowTooltip(false);
    setShowEllipsis(true); // Show the ellipsis icon after copy

    setTimeout(() => {
      setIsCopiedShow(false);
    }, 2000);
  }, [setIsCopied]);

  const handleMouseEnter = () => {
    if (!isCopiedShow) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close the dropdown and show the ellipsis icon if clicked outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowEllipsis(true); // Show the ellipsis icon if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {userNameInital && message && [renderUserMessage(), renderAIResponse()]}
      {aiResponse?.props?.children !== false && aiResponse}
      {/* {aiResp} */}
      {showButtons && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LikeDislikebtn
            userReaction={messageContext?.userReaction}
            handleAction={handleAction}
            LikeDislikeIconId={LikeDislikeIconId}
            IconWithTooltip={IconWithTooltip}
            DislikeIconId={DislikeIconId}
          />
          <CopyButton
            handleCopy={handleCopy}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            isCopiedShow={isCopiedShow}
            CopyIconId={CopyIconId}
            showTooltip={showTooltip}
            IconWithTooltip={IconWithTooltip}
          />
          <PrintButton
            printMessages={printMessages}
            messageContextId={messageContext?.id}
            PrintIconId={PrintIconId}
            IconWithTooltip={IconWithTooltip}
          />
          <div className='desktop-visible'>
            <Downloader aiResponse={aiResponse} IconWithTooltip={IconWithTooltip} messageContextId={messageContext?.id} />
          </div>
          <ShowEllipsis
            dropdownOpen={dropdownOpen}
            toggleDropdown={toggleDropdown}
            EllipsisIconId={EllipsisIconId}
            showEllipsis={showEllipsis}
            dropdownRef={dropdownRef}
            setShowEllipsis={setShowEllipsis}
            handleCopy={handleCopy}
            CopyIconId={CopyIconId}
            printMessages={printMessages}
            PrintIconId={PrintIconId}
            messageContextId={messageContext?.id}
            aiResponse={aiResponse}
            IconWithTooltip={IconWithTooltip}
          />
        </div>
      )}

      {/* Only the selected message is rendered for printing */}
      {selectedMessage && (
        <div style={{ display: 'none' }}>
          <AiResponsePrint ref={printMsgRef} aiResponse={selectedMessage} />
        </div>
      )}

      {showReasons && messId == messageContext?.id && (
        <div className="border rounded dislike-btn py-4 px-3">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <p>Why is this a bad response?</p>
            <button style={{ border: 'none', backgroundColor: 'white', marginBottom: '10px' }} onClick={handleClearShowReason}>
              {/* &times; */}
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form>
            <div className="flex gap-2">
              <textarea placeholder="Provide Feedback" onChange={e => setInputData(e.target.value)} />
              <button onClick={e => handleFeedback(e)}>Submit</button>
            </div>
            {errorMessage && <div style={{ color: 'Red' }}>{errorMessage}</div>}
          </form>
        </div>
      )}
      {/* Empty div that will be scrolled to */}
      <div ref={messagesEndRef}></div>
    </>
  );
};

export default LikeDislikeButton;

const LikeDislikebtn = ({ userReaction, handleAction, LikeDislikeIconId, IconWithTooltip, DislikeIconId }) => {
  return (
    <>
      <button className={`btn ${userReaction == 'Liked' ? 'active' : ''}`} onClick={() => handleAction('Liked')} style={{ border: 'none' }}>
        <LikeIcon iconId={LikeDislikeIconId} color={userReaction == 'Liked' ? 'orange' : 'black'} />
        <IconWithTooltip id={LikeDislikeIconId} tooltipText="Like" />
      </button>
      <button
        className={`btn ${userReaction == 'Disliked' ? 'active' : ''}`}
        onClick={() => handleAction('Disliked')}
        style={{ border: 'none' }}
      >
        <DisLikeIcon iconId={DislikeIconId} color={userReaction == 'Disliked' ? 'orange' : 'black'} />
        <IconWithTooltip id={DislikeIconId} tooltipText="Dislike" />
      </button>
    </>
  );
};

const CopyButton = ({ handleCopy, handleMouseEnter, handleMouseLeave, isCopiedShow, CopyIconId, showTooltip, IconWithTooltip }) => {
  return (
    <button
      onClick={handleCopy}
      style={{ border: 'none', background: 'none' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="desktop-visible"
    >
      {isCopiedShow ? (
        <CheckIcon iconId={CopyIconId} />
      ) : (
        <>
          <CopyIcon iconId={CopyIconId} />
          {showTooltip && <IconWithTooltip id={CopyIconId} tooltipText="Copy" />}
        </>
      )}
    </button>
  );
};

const PrintButton = ({ printMessages, messageContextId, PrintIconId, IconWithTooltip }) => {
  return (
    <div className="desktop-visible">
      <button className={`btn`} onClick={() => printMessages(messageContextId)} style={{ border: 'none' }}>
        <PrintIcon iconId={PrintIconId} />
        <IconWithTooltip id={PrintIconId} tooltipText="Print" />
      </button>
    </div>
  );
};

const ShowEllipsis = ({
  dropdownOpen,
  toggleDropdown,
  EllipsisIconId,
  showEllipsis,
  dropdownRef,
  setShowEllipsis,
  handleCopy,
  CopyIconId,
  printMessages,
  PrintIconId,
  messageContextId,
  aiResponse,
  IconWithTooltip
}) => {
  return (
    <div className="menu_item">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle>
          {showEllipsis ? (
            <EllipsisIcon iconId={EllipsisIconId} onClick={() => setShowEllipsis(false)} />
          ) : (
            <>
              <div className="d-flex gap-4" ref={dropdownRef}>
                <DropdownItem onClick={handleCopy}>
                  <CopyIcon iconId={CopyIconId} />
                </DropdownItem>
                <DropdownItem className="printBtn-hide-on-mobile" onClick={() => printMessages(messageContextId)}>
                  <PrintIcon iconId={PrintIconId} />
                </DropdownItem>
                <DropdownItem>
                  <Downloader aiResponse={aiResponse} IconWithTooltip={IconWithTooltip} messageContextId={messageContextId}/>
                </DropdownItem>
              </div>
            </>
          )}
        </DropdownToggle>
      </Dropdown>
    </div>
  );
};