import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../../../../app/shared/reducers/reducer.utils';

// ✅ Define Type for API Response
interface LinkedVariations {
  id: number;
  name: string;
  per_message_price: string;
  base_cart_url: string;
}

interface AddonFeatureState {
  subscription_id: string | null;
  subscription_plan_name: string | null;
  linked_variations: LinkedVariations | null;
  loading: boolean;
  errorMessage: string | null;
}

// ✅ Initial State
const initialState: AddonFeatureState = {
  subscription_id: null,
  subscription_plan_name: null,
  linked_variations: null,
  loading: true,
  errorMessage: null,
};

const getSubscriptionsUrl = 'api/request-addons';

// ✅ Async Thunk for API Call (No Parameters Needed)
export const fetchSubscriptions = createAsyncThunk<AddonFeatureState, void, { rejectValue: string }>(
  'addonFeature/fetchSubscriptions',
  async (_, { rejectWithValue }) => {
    try {
      const requestUrl = `${getSubscriptionsUrl}`;
      const response = await axios.get<AddonFeatureState>(requestUrl);
      return response.data;
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.response?.data?.message || error.message || 'Something went wrong');
    }
  },
  { serializeError: serializeAxiosError }
);

// ✅ Create Slice
const addonFeatureSlice = createSlice({
  name: 'addonFeature',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriptions.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscription_id = action.payload.subscription_id;
        state.subscription_plan_name = action.payload.subscription_plan_name;
        state.linked_variations = action.payload.linked_variations;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload ?? 'Failed to fetch data';
      });
  },
});

export default addonFeatureSlice.reducer;
