import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { fetchSubscriptions } from '../../entities/addOnMessage-feature/addOnMessagefeature.reducer';

const AddOnMessagesModal = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state?.authentication?.account);
  const messageCount = useAppSelector(state => state?.aiChat?.messageCount);
  const { loading, subscription_id, linked_variations } = useAppSelector(state => state.addonFeature);

  const [totalPrice, setTotalPrice] = useState(0);
  const [perMessagePrice, setPerMessagePrice] = useState(0);
  const [selectedMessages, setSelectedMessages] = useState(10);
  const [isOpen, setIsOpen] = useState(false);

  // ✅ Call API if message balance is 0
  const toggle = () => {
    if (!loading) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (Object.keys(messageCount).length > 0) {
      if (messageCount?.balance == 0 && account?.planType == 'paid' && !account?.trial) {
        dispatch(fetchSubscriptions());
        setIsOpen(true);
      }
    }
  }, [account, messageCount?.balance]);

  // ✅ Set Subscription & Pricing Data
  useEffect(() => {
    if (subscription_id) {
      setPerMessagePrice(parseFloat(linked_variations?.per_message_price || 0));
      setTotalPrice(10 * parseFloat(linked_variations?.per_message_price) || 0);
    }
  }, [subscription_id]);

  // ✅ Handle slider input for quantity selection
  const handleSliderChange = event => {
    const value = parseInt(event.target.value);
    setSelectedMessages(value);
    setTotalPrice(value * perMessagePrice);
  };

  // ✅ Redirect to Payment URL on purchase confirmation
  const handleConfirmPurchase = () => {
    if (!linked_variations) {
      alert('Error: Linked Variations not found!');
      return;
    }
    const baseURL = linked_variations?.base_cart_url;
    const paymentUrl = `${baseURL}?add-to-cart=${linked_variations?.id}&quantity=${selectedMessages}`;

    window.open(paymentUrl, '_blank');
    setIsOpen(false);
  };

  return (
    <>
      <Modal className="msgAddon_modal" isOpen={isOpen} toggle={toggle} centered>
        <>
          {!loading ? (
            <>
              <ModalHeader toggle={toggle}>Purchase Add-On Messages</ModalHeader>
              <ModalBody>
                <p className="purchase-msg">Select the number of messages you want to purchase:</p>

                <p className="price-msgnum">
                  <strong>{selectedMessages}</strong>
                </p>
                <p className="price-msg">
                  Price per Message <strong>${perMessagePrice.toFixed(2)}</strong>
                </p>
                <div className="price-range">
                  <input
                    className="custom-range"
                    type="range"
                    min="10"
                    max="100"
                    step="10"
                    value={selectedMessages}
                    onChange={handleSliderChange}
                    style={{
                      background: `linear-gradient(90deg, #FF8A00 ${((selectedMessages - 10) / 90) * 100}%, #F6F6F6 ${
                        ((selectedMessages - 10) / 90) * 100
                      }%)`,
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <p className="totl-cost">
                  Total Cost <strong>${totalPrice.toFixed(2)}</strong>
                </p>
                <Button color="primary" onClick={handleConfirmPurchase}>
                  Pay Now
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody className="d-flex justify-content-center align-items-center">
                <img src="../../../../../content/images/conductor_loader.gif" width={200} className="" />
              </ModalBody>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default AddOnMessagesModal;
