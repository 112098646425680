import React, { forwardRef, useMemo } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../../../../app/config/store';

interface ChatPrintContentProps {
  messages: { user: string; ai: string }[];
  // ref: React.Ref<HTMLDivElement>;
}

// Forwarding ref for `react-to-print`
const ChatPrintContent = forwardRef<HTMLDivElement, ChatPrintContentProps>(({ messages }, ref) => {
  const account = useAppSelector(state => state.authentication.account);
  // const userName = account ? `${account.firstName || ''} ${account.lastName || ''}`.trim() : 'User';
  const userName = useMemo(() => (account ? `${account.firstName || ''} ${account.lastName || ''}`.trim() : 'User'), [account]);
  return (
    <div ref={ref} className="chat-print-container">
      <h2 className="chat-print-heading">Chat History</h2>
      {messages.length > 0 ? (
        messages.map((msg, index) => (
          <div key={index} className="chat-print-message">
            <div>
              <strong className="chat-print-user">{userName}: </strong>
              <span className="chat-print-text">{msg.user}</span>
            </div>
            <div className="cpa-response-container">
              <strong className="cpa-response-heading">CPA Pilot :</strong>
              <div className="cpa-markdownContent">
                <Markdown remarkPlugins={[remarkGfm]}>{msg.ai}</Markdown>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No messages to display.</p>
      )}
    </div>
  );
});

export default ChatPrintContent;
