import React, { forwardRef } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const AiResponsePrint = forwardRef<HTMLDivElement, { aiResponse: string }>(({ aiResponse }, ref) => (
  <div ref={ref} className="chat-print-container">
    <div className="cpa-response-container">
      <Markdown remarkPlugins={[remarkGfm]}>{aiResponse || 'No response available'}</Markdown>
    </div>
  </div>
));

export default AiResponsePrint;
