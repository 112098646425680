import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { parseMarkdownToTable } from "./Utils";
import { DownloadIcon } from "../../shared/icon-svg/icon";

const Downloader = ({ aiResponse, IconWithTooltip, messageContextId }) => {
    const [markdown, setMarkdown] = useState("");
    const [hasTable, setHasTable] = useState(false);
    const DonwloadIconId = messageContextId ? `DonwloadIconId-${messageContextId}` : 'DonwloadIconId-default';
    const [iconTooltipOpen, setIconTooltipOpen] = useState(false); // Manage tooltip open state


    useEffect(() => {
        if (aiResponse && aiResponse?.props?.children) {
            const aiData = aiResponse?.props?.children;
            const csvContent = parseMarkdownToTable(aiData);
            setMarkdown(aiData);

            if (csvContent) {
                setHasTable(true);
            } else {
                setHasTable(false);
            }
        }
    }, [aiResponse]);

    const handleDownloadCSV = () => {
        const aiData = aiResponse?.props?.children; // Get the selected file
        const csvContent = parseMarkdownToTable(aiData); // Parse the markdown into CSV format
        setMarkdown(aiData); // Update markdown state with the file content

        if (csvContent) {
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "table.csv");
        } else {
            alert("No table found in the markdown file.");
        }
    };

    const toggleTooltip = () => {
        setIconTooltipOpen(!iconTooltipOpen);
    };

    return (
        <div className="dwlicon-mobile-view">
            {hasTable && (
                <>
                 <DownloadIcon 
                 iconId={DonwloadIconId} 
                 onClick={handleDownloadCSV} 
                 onMouseEnter={toggleTooltip}
                 onMouseLeave={toggleTooltip}/>
                    {/* <FontAwesomeIcon
                        icon={faFileDownload}
                        onClick={handleDownloadCSV}
                        style={{ fontSize: '19px' }}
                        color="black"
                        id={DonwloadIconId}
                        onMouseEnter={toggleTooltip}
                        onMouseLeave={toggleTooltip}
                    /> */}
                    {iconTooltipOpen && (
                        <IconWithTooltip id={DonwloadIconId} tooltipText="Download" />
                    )}
                </>

            )}
        </div>
    );
};

export default Downloader;

