import React, { useEffect, useRef, useState } from 'react';
import AiChatDeleteDialog from '../../entities/ai-chat/ai-chat-delete-dialog';
import ChatPrintContent from '../../entities/ai-chat/chatPrintContent';
import { getEntities, getEntity, updateEntity, getAiChatBYSearchQuery } from '../../entities/ai-chat/ai-chat.reducer';
import {
  setShowTemplates,
  setUserOldConversationId,
  setShowExtraPrompts,
  setShowSubscriptionAlert,
  setBotSelected,
} from '../../entities/chat-dashboard/chat-dashboard.reducer';
import { setSidebarToggle } from '../../entities/sidebar/sidebar.reducer';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { getSortState, JhiItemCount, JhiPagination, Translate, ValidatedField } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { MoreSvgIcon, PencilSvgIcon, PrintSvgIcon, StarsSvgIcon, TrashSvgIcon } from '../../../../../app/shared/icon-svg/icon';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LikeDislikeButton from './LikeDislikeButton';

interface Props {
  updateSelectedConversation: any;
  updateUserConversation: any;
  userOldConversationId: any;
  isOpenFavourites: any;
  setSelectedBot: any;
}
export const AichatListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  var dataList = useAppSelector(state => state?.aiChat?.entities);
  const sidebarOpen = useAppSelector(state => state.sidebar.sidebarOpen);
  const account = useAppSelector(state => state.authentication.account);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1000, 'id', DESC), location.search)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isItemEditable, setItemEditable] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(0);
  const [userNameInital, setUserNameInital] = useState('');
  const [printMessages, setPrintMessages] = useState<{ user: string; ai: string; messageId: number }[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const toggle = () => setIsOpen(!isOpen);
  const printRef = React.useRef<HTMLDivElement>(null);

  const configUserChars = account => {
    if (account?.firstName != null && account?.firstName != undefined && account?.firstName != '') {
      setUserNameInital(account?.firstName?.charAt(0));
    } else {
      setUserNameInital(account?.email?.charAt(0));
    }
  };

  useEffect(() => {
    configUserChars(account);
  }, []);

  const getAllEntities = () => {
    if (props.isOpenFavourites) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `favourite.equals=${true}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [isOpen]);

  const handleNavClick = async data => {
    dispatch(setBotSelected(data.aiAssistant));
    let chatData = { currentChatId: data.id, currentPrompt: '' };
    localStorage.setItem('chatData', JSON.stringify(chatData));
    setItemEditable(false);
    setErrorMessage('');
    props.setChatTitleLoaded({
      chatId: data.id,
      isTitleLoaded: true,
    });
    sidebarOpen ? dispatch(setSidebarToggle(!sidebarOpen)) : '';
    let response = await dispatch(getEntity(data.id));
    dispatch(setShowExtraPrompts(false));

    let chatStr = [];
    if (response?.payload?.data) {
      let generatedMessages = response.payload.data.generatedMessages;
      // setGeneratedMessages(response.payload.data.generatedMessages)
      if (generatedMessages && generatedMessages.length > 0) {
        dispatch(setShowTemplates(false));
        // const lastElement = generatedMessages[generatedMessages.length - 1];
        // const promtPersona = lastElement && lastElement.userPrompt && lastElement.userPrompt.promptPersona;
        // if (promtPersona) {
        //   props.handleLastSelectedPersona(promtPersona);
        // } else {
        //   props.handleLastSelectedPersona('AISalesAssistant');
        // }
        chatStr = generatedMessages.map(message => {
          let aiResp = message.rawMessage.includes('<!DOCTYPE html>') ? (
            message.rawMessage
          ) : (
            <Markdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: ({ node, ...props }) => {
                  return (
                    <a {...props} target="_blank">
                      {props.children}
                    </a>
                  );
                },
              }}
            >
              {message.rawMessage}
            </Markdown>
          );
          // chatStr +=
          //   '<div class="usertext"><strong><span>' +
          //   userNameInital +
          //   '</span> You</strong>' +
          //   (message.userPrompt && message.userPrompt.promptText ? message.userPrompt.promptText : message.aiChat.title) +
          //   '</div>' +
          //   '<div class="cpaText"><strong><img class="cpapilot-chat-logo" src="content/images/logo.png" alt="Logo"/>CPA Pilot</strong></div>' +
          //   aiResp;
          // return <LikeDislikeButton userNameInital={userNameInital} message={message} aiResp={aiResp} cta ={true} chatId={data.id}/>;
          return <LikeDislikeButton userNameInital={userNameInital} message={message} aiResp={aiResp} cta={true} />;
        });

        props.updateSelectedConversation(data.id, chatStr);
        dispatch(setShowSubscriptionAlert(false));
      } else {
        props.updateSelectedConversation(data.id, []);
        if (!account.subscriptionActive) {
          dispatch(setShowSubscriptionAlert(true));
        }
      }
    }
  };

  const saveUpdatedTitle = async event => {
    const entity = {
      ...selectedItem,
      title: event.target.value,
    };
    if (!entity.title.trim()) {
      setErrorMessage('Title cannot be blank');
    } else {
      setErrorMessage('');
      await dispatch(updateEntity(entity));
      getAllEntities();
      setItemEditable(false);
    }
  };

  const setItemFavoriteable = async selectedItem => {
    let isItemFavorite = selectedItem.favourite;
    if (isItemFavorite) {
      isItemFavorite = false;
    } else {
      isItemFavorite = true;
    }

    const entity = {
      ...selectedItem,
      favourite: isItemFavorite,
    };
    await dispatch(updateEntity(entity));
    getAllEntities();
  };

  const handleDeleteItem = () => {
    setLoadModal(true);
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef, // ensures we are printing the correct content
    documentTitle: 'Chat_History',
    preserveAfterPrint: true,
    pageStyle: `
    @page { 
      size: A4; 
      margin: 0;
      padding:10mm;
    }
    
    body { 
      font-size: 14px; 
      margin: 10mm;
    }
  `,

    onBeforePrint: React.useCallback(() => {
      console.log('`onBeforePrint` called');
      return Promise.resolve();
    }, []),

    onAfterPrint: React.useCallback(() => {
      console.log('`onAfterPrint` called');
      setPrintMessages([]); //
    }, []),

    onPrintError: (location, error) => {
      console.error(`Print error at ${location}:`, error);
      alert('Printing failed. Please try again.');
    },
  });

  useEffect(() => {
    if (printMessages.length > 0) {
      setTimeout(() => {
        handlePrint();
      }, 500);
    }
  }, [printMessages]);

  const printConversation = async (chatId: string) => {
    const selectChat = dataList?.find(chat => chat.id === chatId);

    if (selectChat && selectChat?.generatedMessages && selectChat?.generatedMessages.length > 0) {
      const conversationMessages = selectChat?.generatedMessages.map(msg => ({
        user: msg?.userPrompt?.promptText,
        ai: msg?.rawMessage,
        messageId: msg?.userPrompt?.id,
      }));
      const sortedMessage = conversationMessages.sort((a, b) => a.messageId - b.messageId);
      setPrintMessages(sortedMessage);
      setTimeout(() => handlePrint(), 500);
    } else {
      console.error('No messages to print!');
    }
  };

  const handleCloseModal = updateSuccess => {
    if (updateSuccess) {
      props.updateSelectedConversation(null, []);
      props.updateUserConversation([]);
      dispatch(setUserOldConversationId(null));
      dispatch(setShowTemplates(true));
    }
    setLoadModal(false);
  };

  // Function to group dates by month
  const groupAiChatListByMonth = () => {
    const months = [];
    dataList.forEach(chat => {
      const date = new Date(chat.createdDate);
      const currentYear = new Date().getFullYear();

      const createdYearName = date.toLocaleString('en-US', {
        year: 'numeric',
      });

      let monthName = '';
      if (currentYear == createdYearName) {
        monthName = date.toLocaleString('en-US', {
          month: 'long',
        });
      } else {
        monthName = date.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        });
      }

      let index = months.findIndex(item => item['month'] === monthName);
      if (index == -1) {
        months.push({ month: monthName, chats: [chat] });
      } else {
        months[index].chats.push(chat);
      }
    });
    return months;
  };

  const toggleCollapse = collapseId => {
    if (openCollapse === collapseId) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(collapseId);
    }
  };

  const groupedDataByMonths = groupAiChatListByMonth();

  return (
    <>
      <AiChatDeleteDialog
        loadModal={loadModal}
        selectedItem={selectedItem}
        handleCloseModal={handleCloseModal}
        handleGetAllEntities={getAllEntities}
        setUserConversationId={props.setUserConversationId}
      />
      <Navbar fixed="left" className="sidedesign">
        <Nav className="w-100" vertical>
          {groupedDataByMonths.map((data, idx) => {
            return (
              <div className="borderCollapse">
                <a onClick={() => toggleCollapse(idx)} className={openCollapse === idx ? 'activeCollapse' : 'Collapseclose '}>
                  {data?.month}
                </a>
                <Collapse isOpen={openCollapse === idx}>
                  {data?.chats.map((product, i) => (
                    <NavItem key={`${i}`} style={props.userOldConversationId === product.id ? { backgroundColor: '#ececec' } : {}}>
                      <div className="d-flex justify-content-between align-items-center border rounded">
                        <div className="overflow-line">
                          {selectedItem.id === product.id && isItemEditable ? (
                            <ValidatedField
                              id="ai-chat-title"
                              name="title"
                              data-cy="title"
                              type="text"
                              defaultValue={product.title}
                              onBlur={e => saveUpdatedTitle(e)}
                            />
                          ) : (
                            <NavLink onClick={() => handleNavClick(product)}>{product.title as string} </NavLink>
                          )}
                          {
                            <span className="px-2" style={{ fontSize: '13px', color: 'GrayText', cursor:"pointer", display:"block" }} onClick={() => handleNavClick(product)}>
                              {product.aiAssistant?.botName}
                            </span>
                          }
                        </div>
                        <div className="d-flex align-items-center">
                          <a
                            onClick={() => setItemFavoriteable(product)}
                            style={product.favourite ? { color: '#ffa500' } : {}}
                            className={product.favourite ? 'favourite' : 'un-favourite'}
                          >
                            <StarsSvgIcon />
                          </a>
                          {/* More button with dropdown */}
                          <Dropdown className="option_item" key={i} isOpen={isOpen && product.id === selectedItem.id} toggle={toggle}>
                            <DropdownToggle caret onClick={() => setSelectedItem(product)}>
                              <MoreSvgIcon />
                              <DropdownMenu>
                                {/* <DropdownItem
                                onClick={() => setItemFavoriteable()}
                                style={product.favourite ? { color: '#ffa500' } : {}}
                                className={product.favourite ? 'favourite' : 'un-favourite'}
                              >
                                <StarsSvgIcon /> Favorite
                              </DropdownItem> */}
                                <DropdownItem onClick={() => setItemEditable(true)}>
                                  {' '}
                                  <PencilSvgIcon /> Rename
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDeleteItem()}>
                                  {' '}
                                  <TrashSvgIcon /> Delete
                                </DropdownItem>
                                <DropdownItem className="printBtn-hide-on-mobile" onClick={() => printConversation(product?.id)}>
                                  {' '}
                                  <PrintSvgIcon /> Print
                                </DropdownItem>
                              </DropdownMenu>
                            </DropdownToggle>
                          </Dropdown>
                        </div>
                      </div>
                      {errorMessage && product.id === selectedItem.id && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </NavItem>
                  ))}
                </Collapse>
              </div>
            );
          })}
        </Nav>
      </Navbar>
      <div style={{ display: 'none' }}>
        <ChatPrintContent ref={printRef} messages={printMessages} />
      </div>
    </>
  );
};

export default AichatListView;
