import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';

export const parseMarkdownToTable = (markdown: string): string | null => {
  if (!markdown) return '';

  const tree = unified().use(remarkParse).use(remarkGfm).parse(markdown);
  let csvData: string[] = [];

  // Check if there's a CSV inside a code block
  let codeBlockCsvDetected = false;

  // Define CSV pattern globally inside the function to detect CSV content
  const csvPattern = /^[^,\n]+(,[^,\n]+)+$/; // Simple CSV line matching pattern (to look for rows with commas)

  // Iterate over children of the markdown tree
  tree.children.forEach(node => {
    if (node.type === 'code') {
      // If it's a code block, check for CSV-like content
      const codeContent = node.value.trim(); // Log code block content

      if (codeContent.split('\n').every(line => csvPattern.test(line))) {
        codeBlockCsvDetected = true;
      }
    }

    if (node.type === 'table') {
      let rows = node.children.map(row => {
        return row.children.map(cell => {
          // ✅ Extracting text properly, handling bold text (**text**) cases
          let cellText = '';

          if (cell.children && cell.children.length > 0) {
            cellText = cell.children.map(child => (child.type === 'text' ? child.value : child.children?.[0]?.value || '')).join(' ');
          }

          // CSV-safe formatting (escape commas & quotes)
          if (cellText.includes(',') || cellText.includes('"')) {
            cellText = `"${cellText.replace(/"/g, '""')}"`;
          }

          return cellText;
        });
      });

      if (rows.length > 0) {
        csvData.push(rows[0].join(',')); // ✅ Add header row
        csvData.push(...rows.slice(1).map(row => row.join(','))); // ✅ Add remaining rows including first column
      }
    }
  });

  // If a CSV was detected in a code block, return that instead of the table data
  if (codeBlockCsvDetected) {
    return markdown.split('\n')
      .filter(line => csvPattern.test(line))
      .join('\n');
  }

  if (csvData.length) {
    return csvData.join('\n');
  }
  return null;
};
